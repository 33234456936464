import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import queryString from "query-string"
import "./index.css"
import App from "./components/App"
import OrientationProvider from "./components/OrientationProvider"
import ErrorPage from "./pages/ErrorPage"
import * as serviceWorker from "./serviceWorker"

console.log("v-fng-tk")

const root = document.getElementById("root")
if (root)
  ReactDOM.render(
    <OrientationProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/:accountId/extras/:productionId">
            {({ match }) => {
              const { accountId, productionId } = match.params
              return <App accountId={accountId} productionId={productionId} />
            }}
          </Route>
          <Route path="/:accountId/extras">
            {({ match, location }) => {
              const { accountId } = match.params
              const { eidr } = queryString.parse(location.search)
              return <App accountId={accountId} eidr={eidr} />
            }}
          </Route>
          <Route path="/:accountId/:seriesId?">
            {({ match }) => {
              const { accountId, seriesId } = match.params
              return <App accountId={accountId} seriesId={seriesId} />
            }}
          </Route>
          <Route>
            <ErrorPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </OrientationProvider>,
    root,
  )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
