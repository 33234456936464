// @flow

import axios from "axios"
import type { Production, DataObject } from "./globals/types"

const baseUrl = "https://nbc-sourcereact.global.ssl.fastly.net/api"
const gcpReshuffleAPI =
  "https://us-central1-sage-mind-254718.cloudfunctions.net/nbcu-hello-extras"
const sourceSyncAPI = "https://api.sourcesync.io"
const analyticsAPI = "https://api.sourcesync.io/analytics"
/** Returns the headers to include based on account ID */
const headers = (accountId: string) => {
  return { "X-CUSTOMER-ID": accountId }
}

/** Get a single production by it's guid */
const getProduction = async (
  accountId: string,
  productionId: string,
): Promise<Production> => {
  const { data: production } = await axios.get(
    `${baseUrl}/productions/${productionId}`,
    {
      headers: headers(accountId),
    },
  )
  return production
}

/** Get a single productions by its EIDR */
const getProductionByEidr = async (
  accountId: string,
  eidr: string,
): Promise<Production> => {
  const { data } = await axios.post(
    `${baseUrl}/productions/search`,
    {
      query: {
        eidr: {
          $eq: eidr,
        },
      },
    },
    {
      headers: headers(accountId),
    },
  )
  return data.items[0]
}

/** Get a list of productions for an account, optionally filtering by series. */
const getProductions = async (
  accountId: string,
  seriesId?: string,
): Promise<Array<Production>> => {
  const { data } = await axios.get(`${baseUrl}/productions?count=10000`, {
    headers: headers(accountId),
    params: {
      seriesId,
    },
  })
  return data.items
}

const getDataObjectsForProduction = async (
  accountId: string,
  productionId: string,
): Promise<Array<DataObject>> => {
  const { data } = await axios.get(
    `${baseUrl}/productions/${productionId}/data-objects?count=10000`,
    {
      headers: headers(accountId),
    },
  )
  return data.items
}

const pulse = async (accountId: string, data: {}) => {
  const response = await axios.post(
    `${baseUrl}/pulse`,
    {
      ...data,
    },
    {
      headers: headers(accountId),
    },
  )
  console.log(response)
}

const getProductionByUserId = async (userId: string) => {
  try {
    const { data: clientIds } = await axios.post(gcpReshuffleAPI, {
      id: userId,
    })
    const ids = clientIds.map(client => client.assetId)

    const { data: productions } = await axios.post(
      sourceSyncAPI + "/productions/mapeidr",
      ids,
    )
    return productions
  } catch (err) {
    console.error(err.message)
    return []
  }
}

const flowEventAnalytics = async (
  movieTitle: string,
  status: Boolean,
  currentSession: string = `${Date.now()}`,
  event: number = 32,
  flowFrom: String = null,
) => {
  try {
    await axios.post(analyticsAPI, {
      flag: movieTitle,
      destination: status,
      session: currentSession,
      sess: currentSession,
      type: "flow",
      event: event,
      e: event,
      TLD: window.location.hostname,
      URL: window.location.href,
      dist: 0,
      distName: "Hello Extras",
      organization: 2,
      env: "production",
      environment: "production",
      flow_from: flowFrom,
    })
    return true
  } catch (err) {
    console.error(err.message)
    return []
  }
}

export default {
  getProduction,
  getProductionByEidr,
  getProductions,
  getDataObjectsForProduction,
  flowEventAnalytics,
  getProductionByUserId,
  pulse,
}
