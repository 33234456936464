// @flow

import * as React from "react"
import type { ImageProps } from "./Image"
import Image from "./Image"
import "../css/BackgroundBlurImage.css"

const BackgroundBlurImage = (props: ImageProps) => (
  <div className="background-blur-image">
    <Image
      {...props}
      className={`${props.className || ""} background-blur-image__blur`}
    />
    <Image
      {...props}
      className={`${props.className || ""} background-blur-image__main`}
    />
  </div>
)

export default BackgroundBlurImage
