// @flow

import React from "react"
import { leftArrowWhite } from "../globals/images"
import "../css/BackButton.css"

type Props = {
  onBack: () => mixed,
}

const backHandler = onBack => {
  window.parent.postMessage({ type: 'back'}, '*')
  onBack()
}
const BackButton = ({ onBack }: Props) => (
  <div className="back-button" onClick={() => backHandler(onBack)}>
    <img src={leftArrowWhite} alt="" />
    Back
  </div>
)

export default BackButton
