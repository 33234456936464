// @flow

module.exports = {
  pages: {
    selectProduction: "SelectProductionPage",
    extras: "ExtrasPage",
  },
  contentTypes: {
    castMember: "castMember",
    videoClip: "videoClip",
    gallery: "gallery",
    interactive: "interactive",
  },
  swiperPaginationTypes: {
    bullets: "bullets",
    fraction: "fraction",
    progressbar: "progressbar",
    custom: "custom",
  },
  knownDataTypes: {
    characterName: "characterName",
    castMember: "castMember",
    interactive: "interactive",
    gallery: "gallery", // unfortunately, gallery data types can either be named 'gallery' or 'galleries'
    galleries: "galleries",
    featuredContent: "featuredContent",
    span: "span",
  },
  pulseEventTypes: {
    view: "view",
    impression: "impression",
    interaction: "interaction",
    broadcast: "broadcast",
    session: "session",
    from: "from",
    choice: "choice",
  },
  deviceOrientations: {
    unknown: "Unknown",
    portrait: "Portrait",
    portraitUpsideDown: "PortraitUpsideDown",
    landscapeRight: "LandscapeRight", // device 90ª clockwise from portrait
    landscapeLeft: "LandscapeLeft", // 90ª counter-clockwise from portrait
  },
}
