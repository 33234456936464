// @flow

import React from "react"
import sizeMe from "react-sizeme"
import Swiper from "../../node_modules/swiper/dist/js/swiper"
import "../../node_modules/swiper/dist/css/swiper.min.css"
import "../css/Gallery.css"
import { fullScreenWhite, exitFullScreenWhite } from "../globals/images"

/*
  import the swiper with the absolute path.
  https://github.com/nolimits4web/Swiper/issues/2335
*/

import { pulseEventTypes, swiperPaginationTypes } from "../globals/globals"

type Props = {
  id: string,
  dataObjectId: string,
  paginationType?: string,
  imageUrls: Array<string>,
  /** From React SizeMe */
  size: {
    width: number,
    height: number,
  },
  onSlideChange: (slideIndex: number) => void,
  pulse: (data: {}) => void,
  handleFullscreenChange: boolean => void,
  isFullscreen: boolean,
  albumIndex: number,
}

class Gallery extends React.Component<Props> {
  static defaultProps = {
    paginationType: swiperPaginationTypes.bullets,
  }

  swiper = {}

  componentDidMount() {
    const swiperContainer = document.getElementById(this.props.id)
    if (!swiperContainer) {
      console.warn("Couldn't find swiper container")
      return
    }

    this.swiper = new Swiper(swiperContainer, {
      pagination: {
        el: ".swiper-pagination",
        type: this.props.paginationType,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        // Use slideChangeTransitionEnd (instead of slideChange) so we can prevent it from firing
        // for synthetic slide changes (.slideTo())
        slideChangeTransitionEnd: () =>
          this.props.onSlideChange(this.swiper.activeIndex),
      },
    })

    // When swiper loads, log the 'view' of the first slide.
    if (this.props.pulse) {
      this.props.pulse({
        dataObjectId: this.props.dataObjectId,
        type: pulseEventTypes.view,
        slideIndex: 0,
      })
    }
  }

  componentDidUpdate(prevProps: Props) {
    // If size or album has changed, force a Swiper update
    const { size, albumIndex } = this.props
    if (
      size.width !== prevProps.size.width ||
      albumIndex !== prevProps.albumIndex
    ) {
      if (this.swiper) this.swiper.update()
    }

    if (this.props.id !== prevProps.id) {
      this.swiper.slideTo(0, 0, false)

      // When swiper updates, log the 'view' of the first slide.
      if (this.props.pulse) {
        this.props.pulse({
          dataObjectId: this.props.dataObjectId,
          type: pulseEventTypes.view,
          slideIndex: 0,
        })
      }
    }
  }

  toggleFullScreen = () => {
    this.props.handleFullscreenChange(!this.props.isFullscreen)
  }

  render() {
    const { imageUrls, paginationType, isFullscreen } = this.props
    if (!imageUrls || imageUrls.length === 0) {
      console.warn("no images in Gallery")
      return null
    }

    return (
      // Slider main container
      <div id={this.props.id} className="swiper-container">
        {/* Additional required wrapper */}
        <div className="swiper-wrapper">
          {/* Slides */}
          {imageUrls.map((imageUrl, index) => (
            <div
              key={`galleryImage${index}`}
              className={`swiper-slide${isFullscreen ? " in-fullscreen" : ""}`}
            >
              <img
                src={imageUrl}
                className="gallery-image"
                alt={`${index + 1}`}
              />
            </div>
          ))}
        </div>
        {/* If we need pagination */}
        {paginationType === swiperPaginationTypes.bullets ? (
          <div className="swiper-bullets-container">
            <div className="swiper-pagination" />
          </div>
        ) : null}
        {paginationType === swiperPaginationTypes.fraction ? (
          <>
            <div className="toolbar-shadow" />
            <div className="swiper-pagination" />
            <div className="fullscreen-btn" onClick={this.toggleFullScreen}>
              <img
                src={isFullscreen ? exitFullScreenWhite : fullScreenWhite}
                alt="fullscreen toggle"
              />
            </div>
          </>
        ) : null}

        {/* If we need navigation buttons */}
        <div
          className={`swiper-button-prev ${
            isFullscreen ? "swiper-button--show" : ""
          } ${
            this.swiper && this.swiper.activeIndex === 0
              ? "swiper-button-disabled"
              : ""
          }`}
        />
        <div
          className={`swiper-button-next ${
            isFullscreen ? "swiper-button--show" : ""
          }`}
        />

        {/* If we need scrollbar */}
        {/* <div className="swiper-scrollbar"></div> */}
      </div>
    )
  }
}

export default sizeMe()(Gallery)
