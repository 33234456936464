import React from "react"
import "../css/ScrollBtn.css"

type Props = {
  type: "left" | "right",
  click: () => void
}
const ScrollLeftIcon = (props: Props) => (
  <>
    {props.type === "left" ? (
      <div className={"btn__scroll-left"} onClick={props.click}>&lt;</div>
    ) : (
      <div className={"btn__scroll-right"} onClick={props.click}>&gt;</div>
    )}
  </>
)

export default ScrollLeftIcon
