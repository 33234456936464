// @flow

import React from "react"

/*
  React component wrapper for image tags. 
  
  Requires src and alt props, 
  but will render the alt text with styling as a fallback if src is missing.

  If a fallback prop is passed, use that in the fallback text, otherwise use alt.

  Accepts a className prop that, by default styles the image and the fallback,
  unless a fallbackClassName prop is provided.
*/

export type ImageProps = {
  src: ?string,
  alt: string,
  fallbackText?: string,
  className?: string,
  fallbackClassName?: string,
}

const Image = (props: ImageProps) => {
  const { src, alt, fallbackText, className, fallbackClassName } = props

  if (!src) {
    return (
      <p className={fallbackClassName || className}>{fallbackText || alt}</p>
    )
  }

  return <img src={src} alt={alt} className={className} />
}

export default Image
