// TODO: Handle in gateway, pass experienceId associated with account to pulse
export const experienceId = "5c085a0ef6040821c822c258" // Replace with your Experience ID, provided by Source

/**
 * A list of category names that, while present in the db and would be
 * supported, should not be displayed.
 */
export const categoryBlacklist = [
  "Featured",
  "Features",
  "Featured Content",
  "Top Picks",
]
