import React from 'react'
import { ScrollToTop } from '../globals/images'
import "../css/ScrollToTopButton.css"

type Props = {
  hide: () => void,
  scrollTo: string
}

const handleScrollToTop = (scrollTo: string, hide: () => void) => {
  const [item] = document.getElementsByClassName(scrollTo)
  item.scrollIntoView({
    block: 'nearest',
    behavior: 'smooth'
  })
  // animate scroll then remove button
  window.setTimeout(() => hide(), 500)
}

const ScrollToTopButton = ({ hide, scrollTo }: Props) => {
  return (
    <div onClick={() => handleScrollToTop(scrollTo, hide)} className="scroll-to-top">
      <img src={ScrollToTop} alt="" />
    </div>
  )
}

export default ScrollToTopButton
