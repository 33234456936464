import React from "react"
import { pictureStart } from "../globals/images"
import Image from "../components/Image"
import "../css/ErrorPage.css"

const ErrorPage = () => (
  <div className="error-page">
    <h1 style={{ color: "white" }}>Sorry, no movie fun here.</h1>
    <Image src={pictureStart} alt="start of old film reel" />
  </div>
)

export default ErrorPage
