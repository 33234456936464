// @flow

import { knownDataTypes } from "./globals"
import type { DataObject, Production, Category } from "./types"

export const isGallery = (dataTypeName: string): boolean =>
  dataTypeName === knownDataTypes.gallery ||
  dataTypeName === knownDataTypes.galleries

// Takes a comma and space separated string and returns a list of trimmed strings.
export const commaSplit = (rawString: string): Array<string> => {
  let list = rawString.split(",").map(string => string.trim())

  // If there are extraneous commas in the list, split() will produce
  // empty strings. Remove them.
  list = list.filter(item => item !== "")
  return list
}

/** Takes a stringToReplace and removes it from the end of another string, if it's present. */
export const removeTrailing = (
  stringToRemove: string,
  baseString: string,
): string => {
  const trailers = baseString.slice(
    baseString.length - stringToRemove.length,
    baseString.length,
  )

  if (trailers === stringToRemove) {
    return baseString.slice(0, baseString.length - stringToRemove.length)
  }

  return baseString
}

// https://gist.github.com/tjmehta/9204891
/* eslint-disable */
export const objectToQuerystring = (obj: {}) => {
  return Object.keys(obj).reduce(function(str, key, i) {
    var delimiter, val
    delimiter = i === 0 ? "&" : "&"
    key = encodeURIComponent(key)
    val = encodeURIComponent(obj[key])
    return [str, delimiter, key, "=", val].join("")
  }, "")
}
/* eslint-enable */

// Takes a time in seconds and converst to HH:MM:SS string.
export const toTimeString = (timeInSeconds: number): string => {
  if (timeInSeconds < 0) {
    console.warn("Negative number passed to toTimeString, returning")
    return ""
  }

  const hours = Math.floor(timeInSeconds / 3600)
  const minutes = Math.floor((timeInSeconds % 3600) / 60)
  const seconds = Math.floor(timeInSeconds % 60)

  const hoursString = hours > 0 ? `${String(hours)}:` : ""
  const minutesString =
    hours > 0 && minutes < 10 ? `0${minutes}:` : `${String(minutes)}:`
  const secondsString = seconds < 10 ? `0${seconds}` : String(seconds)

  return hoursString + minutesString + secondsString
}

export const preloadImage = (url: string, callback: () => void) => {
  if (!url) return

  const preload = new Image()
  preload.onload = callback
  preload.src = url
}

// A compare function for sorting Cast Member Data Objects by billing order
export const compareBillingOrder = (a: DataObject, b: DataObject) => {
  const firstBillingOrder = a.data.billingOrder
  const secondBillingOrder = b.data.billingOrder

  if (
    typeof firstBillingOrder === "number" &&
    typeof secondBillingOrder === "number" &&
    firstBillingOrder < secondBillingOrder
  )
    return -1
  if (
    typeof firstBillingOrder === "number" &&
    typeof secondBillingOrder === "number" &&
    secondBillingOrder < firstBillingOrder
  )
    return 1

  // If a has a billing order and b doesn't, a should come first
  if (typeof firstBillingOrder === "number") return -1

  // The reverse ^^
  if (typeof secondBillingOrder === "number") return 1

  return 0
}

/**
 * A higher-order function that generates a compare function for sorting extras
 * categories by a list of data type names.
 * Note: cast member data type always comes last
 * */
export const compareCategoryOrder = (
  orderedCategoryNames: Array<string>,
): ((a: Category, b: Category) => -1 | 0 | 1) => (a: Category, b: Category) => {
  // Cast Members always go last
  if (a.name === knownDataTypes.castMember) return 1
  if (b.name === knownDataTypes.castMember) return -1

  const aCategoryIndex = orderedCategoryNames.indexOf(a.name)
  const bCategoryIndex = orderedCategoryNames.indexOf(b.name)

  // If a is ordered and b isn't, a should come first
  if (aCategoryIndex >= 0 && bCategoryIndex === -1) return -1

  // If b is ordered and a isn't, b should come first
  if (bCategoryIndex >= 0 && aCategoryIndex === -1) return 1

  // If neither have an order, treat as equal
  if (aCategoryIndex === -1 && bCategoryIndex === -1) return 0

  // If both are ordered...
  if (aCategoryIndex < bCategoryIndex) return -1
  if (aCategoryIndex > bCategoryIndex) return 1

  // If both are ordered and the same, treat as equal
  return 0
}

// Can catch runtime errors and display their contents in an alert popup.
// Useful for hard-to-debug issues when running in the Experience App
/* eslint-disable */
export const startErrorLog = () => {
  window.onerror = (message, file, line, column, errorObject) => {
    column = column || (window.event && window.event.errorCharacter)
    var stack = errorObject ? errorObject.stack : null

    alert(`Error:
           ${message}
           ${file}
           ${line}
           ${column}
           ${JSON.stringify(errorObject)}`)
  }
}
/* eslint-enable */

/** Production-level assets have standardized 'name's
 * 'app-background', 'poster-portrait', 'poster-landscape', and 'logo'.
 * Get them, map them to fields on the production, and return.
 * If the production has no media, return null, as we shouldn't show
 * it in the experience.
 */
export const mapImagesToProduction = (production: Production) => {
  if (!production) {
    console.warn("No production in mapImagesToProduction")
    return null
  }

  if (!production.media || production.media.length === 0) {
    console.warn("No media on production", production)
    return null
  }

  const { media } = production
  let appBackgroundUrl
  let posterPortraitUrl
  let posterLandscapeUrl
  let logoUrl
  media.forEach(mediaItem => {
    if (mediaItem.type === "image") {
      if (mediaItem.name === "app-background") appBackgroundUrl = mediaItem.url
      if (mediaItem.name === "poster-portrait")
        posterPortraitUrl = mediaItem.url
      if (mediaItem.name === "poster-landscape")
        posterLandscapeUrl = mediaItem.url
      if (mediaItem.name === "logo") logoUrl = mediaItem.url
    }
  })

  return {
    ...production,
    appBackgroundUrl,
    posterPortraitUrl,
    posterLandscapeUrl,
    logoUrl,
  }
}

export const getURLParameters = url =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => (
      // eslint-disable-next-line
      (a[v.slice(0, v.indexOf("="))] = v.slice(v.indexOf("=") + 1)), a
    ),
    {},
  )
