// @flow

import * as React from "react"
import { deviceOrientations } from "../globals/globals"
import type { DeviceOrientation } from "../globals/types"

export const OrientationContext: React.Context<{
  orientation: DeviceOrientation,
  override: boolean,
}> = React.createContext({
  orientation: deviceOrientations.portrait,
  override: true,
})

const style = { color: "white" }

// Provides the device's real-world orientation, based on accelerometer data,
// or the orientation provided by onOrientationChange, if it ever fires
class OrientationProvider extends React.Component<
  { debug: boolean, children: React.Node },
  {
    latestNewOrientation: { time: Date, orientation: DeviceOrientation },
    orientation: DeviceOrientation,
    derivedOrientation: DeviceOrientation,
    alpha: number,
    beta: number,
    gamma: number,
    override: boolean,
  },
> {
  state = {
    latestNewOrientation: { time: new Date(), orientation: "initial" },
    orientation: deviceOrientations.unknown,
    derivedOrientation: deviceOrientations.unknown,
    alpha: 0,
    beta: 0,
    gamma: 0,
    override: true, // if we ever have an orientationchange event, stop the override
  }

  minOrientationDurationMs = 1000
  latestNewOrientation: ?{ time: Date, orientation: DeviceOrientation } = null

  componentDidMount() {
    window.addEventListener("deviceorientation", this.handleOrientation)
    window.addEventListener("orientationchange", this.handleOrientationChange)
  }

  componentWillUnmount() {
    window.removeEventListener("deviceorientation", this.handleOrientation)
    window.addEventListener("orientationchange", this.handleOrientationChange)
  }

  // Updates the orientation if there have been X consecutive milliseconds
  // of an orientation different from state.orientation
  handleOrientation = (e: {
    absolute: number,
    alpha: number,
    beta: number,
    gamma: number,
  }) => {
    if (!this.state.override) return

    const { debug } = this.props
    const { alpha, beta, gamma } = e
    const { abs } = Math

    // Categorize the current orientation data
    let derivedOrientation = deviceOrientations.unknown
    if (beta > 57 || (beta > 27 && beta > abs(gamma))) {
      derivedOrientation = deviceOrientations.portrait
    } else if (gamma > 40 && abs(beta) < 30) {
      derivedOrientation = deviceOrientations.landscapeRight
    } else if (beta < -57 || (beta < -27 && abs(beta) > abs(gamma))) {
      derivedOrientation = deviceOrientations.portraitUpsideDown
    } else if (gamma < -40 && abs(beta) < 30) {
      derivedOrientation = deviceOrientations.landscapeLeft
    }

    if (debug) this.setState({ derivedOrientation, alpha, beta, gamma })

    if (derivedOrientation === this.state.orientation) {
      return
    }

    if (!this.latestNewOrientation) {
      this.setLatestNewOrientation(derivedOrientation)
      return
    }

    // Compare the derivedOrientation with the latestNewOrientation...
    // If the derivedOrientation is different, update and move on.
    const { orientation } = this.latestNewOrientation
    if (derivedOrientation !== orientation) {
      this.setLatestNewOrientation(derivedOrientation)
      return
    }

    // If the derivedOrientation is the same as the latestNewOrientation,
    // compare the times to see if we should update.
    // Ignore Unknown
    if (
      derivedOrientation !== deviceOrientations.unknown &&
      new Date() - this.latestNewOrientation.time >=
        this.minOrientationDurationMs
    ) {
      this.setState({ orientation: derivedOrientation })
    }
  }

  setLatestNewOrientation = (orientation: ?DeviceOrientation) => {
    if (!orientation) {
      this.latestNewOrientation = null
      return
    }

    const { debug } = this.props
    this.latestNewOrientation = { time: new Date(), orientation }
    if (debug)
      this.setState({ latestNewOrientation: this.latestNewOrientation })
  }

  handleOrientationChange = () => {
    // If we're responding to an orientationchange event, we don't need to override
    // native orientation. Stop the deviceorientation listener.
    window.removeEventListener("deviceorientation", this.handleOrientation)

    const screenOrientation = this.getScreenOrientation()

    if (screenOrientation !== this.state.orientation) {
      this.setState({ orientation: screenOrientation, override: false })
    }
  }

  getScreenOrientation = () => {
    const screenOrientation =
      window.screen.msOrientation ||
      (window.screen.orientation || window.screen.mozOrientation || {}).type

    if (screenOrientation) {
      switch (screenOrientation) {
        case "landscape-primary":
          return deviceOrientations.landscapeRight
        case "landscape-secondary":
          return deviceOrientations.landscapeLeft
        case "portrait-primary":
          return deviceOrientations.portrait
        case "portrait-secondary":
          return deviceOrientations.portraitUpsideDown
        default:
          console.warn("The orientation API isn't supported in this browser")
          return deviceOrientations.unknown
      }
    }

    // Safari
    if (typeof window.orientation === "number") {
      return this.getSafariOrientation(window.orientation)
    }

    console.warn("Couldn't detect device orientation.")
    return deviceOrientations.unknown
  }

  getSafariOrientation = (degrees: number) => {
    switch (degrees) {
      case 0:
        return deviceOrientations.portrait
      case 90:
        return deviceOrientations.landscapeRight
      case -90:
        return deviceOrientations.landscapeLeft
      case 180:
        return deviceOrientations.portraitUpsideDown
      default:
        console.warn(
          "Unexpected window.orientation value: ",
          window.orientation,
        )
        return deviceOrientations.unknown
    }
  }

  render() {
    const { time, orientation } = this.state.latestNewOrientation
    const { debug } = this.props

    return (
      <OrientationContext.Provider
        value={{
          orientation: this.state.orientation,
          override: this.state.override,
        }}
      >
        {debug ? (
          <div>
            <div
              style={{
                position: "absolute",
                zIndex: 99,
                pointerEvents: "none",
              }}
            >
              <p style={style}>Alpha: {this.state.alpha}</p>
              <p style={style}>Beta: {this.state.beta}</p>
              <p style={style}>Gamma: {this.state.gamma}</p>
              <p style={style}>
                derivedOrientation: {this.state.derivedOrientation}
              </p>
              <ul style={{ color: "yellow", padding: 0, margin: 0 }}>
                latestNewOrientation:
                <li style={{ paddingLeft: "1em" }}>
                  {`time: ${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}`}
                </li>
                <li style={{ paddingLeft: "1em" }}>
                  orientation: {orientation}
                </li>
              </ul>
              <p style={{ color: "green" }}>
                App Orientation: {this.state.orientation}
              </p>
            </div>
            {this.props.children}
          </div>
        ) : (
          this.props.children
        )}
      </OrientationContext.Provider>
    )
  }
}

export default OrientationProvider
